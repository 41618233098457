import dayjs from 'dayjs'
// import isMobile from 'is-mobile'
// const mobile = isMobile();
import MobileDetect from 'mobile-detect'
const md = new MobileDetect(window.navigator.userAgent);
const mobile = md.phone()

function dateTimeChat(value) {
    let date_diff = - dayjs(value*1000).diff(dayjs().startOf('day')) / 1000 / 60 / 60;
    let dayFormat = '';
    if (!mobile) {
        if (date_diff < 0) {
            dayFormat = 'Сегодня:';
        } else if (date_diff < 24) {
            dayFormat = 'Вчера:';
        } else {
            dayFormat = 'DD.MM:';
        }
    }
    return dayjs(value * 1000).format(`${dayFormat}HH:mm`).trim()
}

function dateTimeForum(value) {
    return dayjs(value * 1000).format('DD.MM.YY HH:mm')
}

export {dateTimeChat, dateTimeForum}
