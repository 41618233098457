import { generateJSON } from '@tiptap/html'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'

export function deserializeToText(nodes) {
    let result = '';
    if (!('content' in nodes)){
        return result
    }
    let nodesMaxIndex = nodes.content.length-1;
    nodes.content.forEach( (node, nodeIndex) =>{
        let content = '';
        if (node.type === 'text'){
            content = node.text;
        }else if(node.type === 'paragraph'){
            content = deserializeToText(node);
        }
        if ((node.type === 'paragraph' || node.type === 'enter') && nodeIndex<nodesMaxIndex){
            content = content + '\n';
        }
        result = result + content.toString();
    });
    return result
}

export function convertToPlain(html){
    let textJSON = HTMLtoJSON(html)
    return deserializeToText(textJSON)
    // let tempDivElement = document.createElement("div")
    // tempDivElement.innerHTML = html
    // // debugger
    // return tempDivElement.textContent
}

export function textToHTML(text) {
    const blocks = text.split(/(?:\r\n?|\n)/);
    let result = '';
    blocks.forEach(line => {
        result = result + `<p>${line}</p>`
    });
    return result
}

export function HTMLtoJSON (html) {
    return generateJSON(html, [Document, Paragraph, Text ])
}
