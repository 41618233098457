<template lang="pug">
div(:class="{'chat-message': true, 'chat-message_me':chatMessage.is_me, 'chat-message_wait':showWait}" @dblclick="startEditing" :data-uuid="chatMessage.uuid")
    template(v-if="!is_editing")
        a(v-if="is_search" class="chat-message_goto" @click="gotoClick") перейти
        span(class="chat-message_before") *
        span(class="chat-message__time_before") [
        span(class="chat-message__time" v-html="formattedDateTime")
        span(class="chat-message__time_after" v-html="'] '")
        chat-user(class="chat-message__user" :user="user" :displayed_name="chatMessage.user_displayed_name" @click="chatUserClick")
        span(class="chat-message__user_after") :
        span(class="chat-message__text_before" v-html="' '")
        span(class="chat-message__text" v-html="chatMessage.text" :class="[user.text_color]")
        span(class="chat-message_after") *
    template(v-else)
        div(class="chat-message__edit" @keydown.enter.prevent="confirmEditing($event)")
            z-editor(class="chat-message__edit__editor" v-model="editingText" :autoFocus="true" placeholder="Редактирование для бога редактирования")
            div(class="chat-message__edit__buttons")
                div(class="chat-message__edit__buttons_inner")
                    z-btn(icon="check" @click="confirmEditing" :transparent="true")
                    z-btn(icon="trash-can" @click="deleteEditing" :transparent="true")
                    z-btn(icon="cancel" @click="cancelEditing" :transparent="true")
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'
import { dateTimeChat } from '../../plugins/dayjs'
import ChatUser from "./Chat-user"
import ZEditor from '../../components/z-editor'
import io from '../../plugins/socketio'
import { convertToPlain, textToHTML } from '../../plugins/editor'

const props = defineProps({
    user: {},
    chatMessage: {},
    is_wait: {type: Boolean, default: false},
    is_editing: {type: Boolean, default: false},
    room_id: {},
    is_search: {type: Boolean, default: false}
})

const emit = defineEmits(['openPrivateMessages', 'goToMessage', 'startEditing', 'stopEditing'])

const showWait = ref(false)
if (props.is_wait) {
    setTimeout(() => {
        showWait.value = true
    }, 2000)
}

const formattedDateTime = computed(() => {
    return dateTimeChat(props.chatMessage.time)
})

function chatUserClick(){
    emit('openPrivateMessages')
}

function gotoClick(){
    emit('goToMessage', props.chatMessage)
}

const editingText = ref('')

function startEditing () {
    if (props.is_wait) {
        console.log('wait')
    } else if (!props.is_editing) {
        let emit_data = {
            uuid: props.chatMessage.uuid,
            room_id: props.room_id
        }
        io.emit('chat_text_edit_start', emit_data, response => {
            if (response && 'is_valid' in response && response.is_valid) {
                emit('startEditing')
                editingText.value = textToHTML(response.message.text)
            }
        })
    }
}

function cancelEditing() {
    emit('stopEditing')
}

function confirmEditing (event) {
    if (!event.shiftKey) {
         chatTextSend('edit');
    }
}

function deleteEditing(){
    chatTextSend('delete');
}


function chatTextSend (action) {
    let emitData = { ...props.chatMessage }
    emitData['text'] = convertToPlain( editingText.value )
    emitData['action'] = action
    emitData['room_id'] = props.room_id
    io.emit('chat_text', emitData, response => {
        emit('stopEditing')
    })
}
</script>

<style scoped>
    .chat-message{
        display: inline-block;
        margin-bottom: var(--chat-messages-margin);
    }

    .chat-message__time_before,
    .chat-message__time_after,
    .chat-message__time{
        font-weight: 300;
    }

    .chat-message__time_after,
    .chat-message__user_after,
    .chat-message__text_before
    {
        /*white-space: pre;*/
    }

    .chat-message_me>.chat-message__time,
    .chat-message_me>.chat-message__time_before,
    .chat-message_me>.chat-message__time_after,
    .chat-message_me>.chat-message__user_after
    {
        display: none;
    }

    .chat-message_before,
    .chat-message_after{
        display: none;
    }

    .chat-message_me>.chat-message_before,
    .chat-message_me>.chat-message_after
    {
        display: inline;
    }

    .chat-message__time,
    .chat-message__time_before,
    .chat-message__time_after
    {
        color: var(--message-time-color)
    }

    .chat-message_wait>.chat-message__time,
    .chat-message_wait>.chat-message__time_before,
    .chat-message_wait>.chat-message__time_after
    {
        color: var(--wait-message-time-color)
    }

    .chat-message__text{
        white-space:pre-line;
        word-break: break-word;
    }

    .chat-message__text>a{
        word-break: break-all;
        color: var(--link-color)
    }

    .chat-message__edit{
        /*margin-right: var(--indent-size);*/
    }

    .chat-message__edit__editor{
        background: var(--editor-background);
        padding: var(--indent-size);
    }

    .chat-message__edit__buttons{
        width: 100%;
        height: 0;
        display: flex;
        justify-content: flex-end;
        /*flex-direction: row;*/
    }

    .chat-message__edit__buttons_inner{
        background: var(--editor-background);
        position: relative;
        width: fit-content;
        height: var(--block-height-medium);
        display: flex;
    }

    .chat-message ::v-deep(.forum-link){
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
        color: var(--link-color);
    }

    .chat-message_goto{
        color: var(--link-color);
        cursor: pointer;
        text-decoration: underline;
        user-select: none;
        margin-right: var(--indent-size-small);
    }
</style>
