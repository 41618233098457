<template lang="pug">
button(:class="[user.name_color, 'chat-user']" v-html="displayedName" @click="onClick")
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
    user: {},
    displayed_name: { default: '' },
})

const displayedName = computed(() => {
    if (props.displayed_name) {
        return props.displayed_name
    } else {
        return props.user.name
    }
})
        // methods:{
            // onClick(){
            //     this.$emit('click')

</script>

<style scoped>
    .chat-user:hover{
        /*filter: var(--user-hover-filter);*/
        /*font-weight: bold;*/
        text-shadow: 0.02px 0px 0px;
    }
    .chat-user{
        cursor: pointer;
        user-select:text;
    }
</style>
