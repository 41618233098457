import { readonly, ref } from 'vue'
import io from '/src/plugins/socketio'

// const activeUsers = []
// const birthdays = {}
// const unread = {}

const createUserState = (activeUsers, birthdays, unread)=>{return {
    activeUsers,
    birthdays,
    unread
}}

const usersState = ref( createUserState([], {}, {}) )

function getActiveUsers () {
    io.emit('get_active_users', response => {
        usersState.value = createUserState(
            Object.values(response['active_users']),
            response['birthdays'],
            response['unread']
        )
        sortUnread()
    })
}

io.on('user_join', userData => {
    if (usersState.value.activeUsers.findIndex( user=>user.id===userData.id)===-1){
        usersState.value.activeUsers.push(userData)
        sortUnread()
    }
})

io.on('user_left', userData => {
    let userIndex = usersState.value.activeUsers.findIndex(user => user.id === userData.id)
    if (userIndex >= 0) {
        usersState.value.activeUsers.splice(userIndex, 1)
    }
})

io.on('birthdays', data=>{
    usersState.value.birthdays = data
})


function addUnread(user){
    let unread_user = {...user};
    delete unread_user['online'];
    unread_user['unread'] = true;
    usersState.value.unread[`user_${user.id}`] = unread_user
    sortUnread()
}

io.on('del_unread', data=>{
    delFromUnread(data)
})

function delFromUnread(userRoomId){
    delete usersState.value.unread[userRoomId]
}

function delUnread(userRoomId){
    io.emit('delete_unread', {id: Number(userRoomId.replace('user_', ''))})
    delFromUnread(userRoomId)
}

function sortUnread () {
    if (usersState.value.unread) {
        usersState.value.activeUsers.sort((user1, user2) => {
            if (user1.id === 1) { //Корви всегда первый
                return -1
            } else if (`user_${user1.id}` in usersState.value.unread && user2.id !== 1) {
                return -1
            } else {
                return 0
            }
        })
    }
}

const mainRoomUnread = ref( false )
const mainRooms = ref( {} )

function setMainRoomUnread( unread ) {
    if (Object.keys(mainRooms.value).length > 0) {
        mainRoomUnread.value = false
    } else {
        mainRoomUnread.value = unread
    }
}
io.on('set_main_room_unread', setMainRoomUnread)

function mainRoomOpen (uuid) {
    // console.log('open', uuid)
    mainRooms.value[uuid] = true
}

function mainRoomClose (uuid) {
    // console.log('close', uuid)
    delete mainRooms.value[uuid];
}

io.on('update_user', user_data=>{
    console.log('update_user','users')

    const updateUser = (user)=>{
        user.name = user_data.name
        user.displayed_name = user_data.displayed_name
        user.name_color = user_data.name_color
        user.text_color = user_data.text_color
    }

    const updateUserInDict = (userList) => {
        let user = userList['user_' + user_data.id]
        if (user) {
            updateUser(user)
        }
    }

    const updateUserInList = (userList) => {
        let user = userList.find(user => user.id === user_data.id)
        if (user) {
            updateUser(user)
        }
    }

    updateUserInList(usersState.value.activeUsers)
    updateUserInDict(usersState.value.unread)
    updateUserInDict(usersState.value.birthdays)
})

io.on('connect', getActiveUsers)
io.on('reconnect', getActiveUsers)
io.on('error', getActiveUsers)

if (io.connected) {
    getActiveUsers()
}

export const useUsers = () => {
    return {
        usersState: readonly(usersState),
        addUnread,
        delUnread,
        setMainRoomUnread,
        mainRoomOpen,
        mainRoomClose,
        mainRooms: readonly(mainRooms),
        mainRoomUnread: readonly(mainRoomUnread)
    }
}
