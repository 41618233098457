import { nextTick } from 'vue'
import { emitter } from '../../plugins/emitter'

export function scrollToMessage (messageUuid, refBodyMessages) {
    nextTick(() => {
        if (messageUuid){
            refBodyMessages.value.querySelector(`div[data-uuid="${messageUuid}"]`).scrollIntoView(true)
        } else {
            refBodyMessages.value.scrollTop = 0
        }
    })
}

export function messagesClick (event) {
    if (event.target.className === 'forum-link') {
        let forumId = event.target.dataset.forum_id
        nextTick(() => {
            emitter.emit('openForum', { forumId: forumId })
        })
    }
}

export function updateUserInMessages(user_data, messages){
    messages.value.forEach(message=>{
        if (message.user.id === user_data.id){
            message.user.displayed_name = user_data.displayed_name
            message.user.text_color = user_data.text_color
            message.user.name_color = user_data.name_color
        }
    })
}
