const commands = [
    {
        value: "/me",
        insert:'/me&nbsp',
        description:'Реплика от первого лица',
        regExp:''
    },
    {
        value: "/dice",
        insert:'/dice 3 12',
        description:'Бросок дайсов /dice [кубики] [грани]',
        regExp: new RegExp('(^/dice) ([0-9]{1,5}) ([0-9]{1,1000})')
    },
    {
        value: "/ddd",
        insert:'/ddd',
        description:'Бросок дайсов 3d12',
        regExp: new RegExp('^\\/(.)\\1*$')
    },
    {
        value: "/d",
        insert:'/d',
        description:'Бросок дайсов 1d12',
        regExp:''
    },
    {
        value: "/pm",
        insert:'/pm&nbsp',
        description:'Открыть ЛС /pm [ник или id]',
        regExp: new RegExp('(^/pm)[\\s|\\u00A0](.*)')
    }
];

export default commands
