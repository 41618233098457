<template lang="pug">
div(class="commands_container")
    ul(class="commands_ul" ref="refCommands")
        li(v-for="(command, index) in commands"
            :key="`command_${index}`"
            @click="selectCommandClick(index)"
            :class="{'command_li':true, 'command_li_selected':index===selectedCommandIndex}"
        )
            span(class="command_li_value") {{command.value}}
            span(class="command_li_description") {{command.description}}
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import commandsList from "./commands"

const emit = defineEmits(['command', 'ready'])
const commands = ref(commandsList)
const selectedCommandIndex = ref(0)

function moveSelected (direction) {
    let newIndex = selectedCommandIndex.value + direction
    let maxIndex = commands.value.length - 1
    if (newIndex < 0) {
        newIndex = maxIndex
    } else if (newIndex > maxIndex) {
        newIndex = 0
    }
    selectedCommandIndex.value = newIndex
}


function selectCommand () {
    // console.log(commands.value[selectedCommandIndex.value])
    emit('command', commands.value[selectedCommandIndex.value])
}

function selectCommandClick (index) {
    selectedCommandIndex.value = index
    selectCommand()
}

emit('ready', {moveSelected, selectCommand})
</script>

<style scoped>
    .commands_container{
        position: relative;
        top: -1px;
    }

    .commands_ul{
        position: absolute;
        overflow: hidden;
        /*padding: var(--indent-size);*/
        background: var(--editor-background);
        top: auto;
        bottom: 100%;
    }

    .command_li{
        position: relative;
        height: var(--block-height-medium);
        line-height: var(--block-height-medium);
        padding: 0 var(--indent-size);
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
    }

    .command_li:hover{
        background: var(--list-hover-background);
    }

    .command_li_selected,
    .command_li_selected:hover
    {
        background: var(--list-selected-background);
    }


    .command_li_value{
        min-width: 3rem;
        display: inline-block;
    }

    .command_li_description{
        font-size: var(--font-size-small);
        margin-left: 1rem;
    }
</style>
