<template lang="pug">
div(:class="{'chat-archive__root':true}")
    z-loading(v-if="isLoading" class="chat-archive__body__loader" size="100" :overlay="true" :timeout="700")
    div(class="chat-archive__header")
        span(:class="{'chat-archive__header__user':true, 'chat-archive__header__user_active':currentRoomId===mainRoomId}" @click="openMainRoom" v-html="'ЧАТ'")
        chat-user(v-for="(user, userIndex) in chatUsers"
            :user="user"
            :displayed_name="user.displayed_name"
            :key="`user_${userIndex}`"
            :class=`{'chat-archive__header__user':true,
                            'chat-archive__header__user_active':currentRoomId===user.id,
                            'chat-archive__header__user_unread':false,
                            'chat-archive__header__user_birthday':user.birthday,
                            'chat-archive__header__user_online':true
                            }`
            @click="openPrivateMessages(user.id)")
    div(class="chat-archive__body" ref="refChatArchiveBody")
        //div(class="chat-archive__body__loader")
            //div {{isLoading}} {{isLoadingStarted}} {{}}
        div(class="chat-archive__body__messages" ref="refChatArchiveBodyMessages" @click="messagesClick")
            chat-message(v-for="(chatMessage, index) in chatMessages" :key="`chat_messages_${index}`"
                :user="chatMessage.user"
                :chatMessage="chatMessage"
                :is_wait="false"
                :is_editing="false"
                :is_search="isSearch"
                :room_id="currentRoomId"
                @openPrivateMessages="openPrivateMessages(chatMessage.user.id)"
                @goToMessage="goToMessage(chatMessage)")
    //div(class="chat-archive__filter")
    //    z-input(label="Период с" type="date")
    //    z-input(label="Период по" type="date")
    div(class="chat-archive__footer")
        z-paginate(v-model="currentPage" :reverse="true" :pages="pages" @change="paginateChange" class="chat-archive__footer__paginate")
        div(class="chat-archive__footer__search")
            z-input(placeholder="Поиск для бога поиска" v-model="search" :dense="true" @change="openSearch")
            z-btn(icon="magnify" :transparent="true" class="chat-archive__footer__search__btn" @click="openSearch")
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
import axios from 'axios'
import ChatMessage from "./Chat-message";
import ChatUser from "./Chat-user";
import { scrollToMessage, messagesClick } from './chatFuncs'
import ZLoading from '../../components/z-loading'
import ZPaginate from '../../components/z-panginate'
// import ZInput from "../../components/z-input";
// export default {
//   components: {ZInput}
// }
const emit = defineEmits(['append_to_route'])
const props = defineProps({
    panel: {}
})

const mainRoomId = 'chat'
const chatMessages = ref([])
const currentRoomId = ref(mainRoomId)
const chatUsers = ref({})
const currentPage = ref(0)
const pages = ref(0)
const search = ref('')
const isSearch = ref(false)
const isLoading = ref(false)
// const isLoadingStarted = ref(false)
const refChatArchiveBody = ref(null)

getPmUsers()
readRouteParams()
openRoom(currentRoomId.value, currentPage.value)

function readRouteParams() {
    if ('room_id' in props.panel) {
        currentRoomId.value = props.panel.room_id
    }
    if ('page' in props.panel) {
        currentPage.value = props.panel.page
    }
    if ('search' in props.panel) {
        search.value = props.panel.search
    }
}

function getPmUsers () {
    axios.get('pm_users').then(response => {
        chatUsers.value = response.data.users
    })
}

function getMessages (roomId, page = 0, id = null, uuid = null) {
    const get_messages_params = {
        room_id: roomId,
        page: page,
        search: search.value
    }
    if (id) {
        get_messages_params['record_id'] = id
    }

    isLoading.value = true
    // isLoadingStarted.value = false
    // setTimeout(()=>{
    //     isLoadingStarted.value = isLoading.value
    // }, 700)

    axios.get('get_messages_archive', { params: get_messages_params }).then(response => {
        currentRoomId.value = roomId
        pages.value = response.data.pages
        currentPage.value = response.data.page
        chatMessages.value = response.data.messages
        isSearch.value = !!search.value
        isLoading.value = false
        // isLoadingStarted.value = false

        scrollToMessage(uuid, refChatArchiveBody)
        // refChatArchiveBody.value.querySelector(`div[data-uuid="${messageUuid}"]`).scrollIntoView(true)

        get_messages_params.page = currentPage.value
        emit('append_to_route', get_messages_params)
    })
}

function openPrivateMessages (userRoomId) {
    getMessages(userRoomId, 0)
}

function openMainRoom () {
    openRoom(mainRoomId)
}

function openSearch () {
    openRoom(currentRoomId.value, 0)
}

function openRoom (roomId, page = 0) {
    getMessages(roomId, page)
}

function paginateChange () {
    getMessages(currentRoomId.value, currentPage.value)
}

function goToMessage (message) {
    search.value = ''
    getMessages(currentRoomId.value, 0, message.id, message.uuid)
}
</script>

<style>
    .chat-archive__root {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    /*.chat-archive__root-loading:before{*/
    /*    content: '';*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*    background: var(--overlay-background);*/
    /*    position: fixed;*/
    /*}*/

    .chat-archive__header {
        height: var(--block-height-big);
        min-height: var(--block-height-big);
        background: var(--chat-header-background);
        width: 100%;
        flex: 0;
        display: inline-flex;
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: var(--indent-size);
    }

    .chat-archive__header__user {
        --chat-archive__header__user_height: calc(var(--block-height-big) - 4px);
        white-space: nowrap;
        margin: 0 calc(var(--indent-size)/5);
        height: var(--chat-archive__header__user_height);
        line-height: var(--chat-archive__header__user_height);
        cursor: pointer;
        user-select: none;
        border-bottom: var(--inactive-tab-border-color) var(--tab-border-height) solid;
    }

    .chat-archive__header__user_online{
        filter: none;
    }

    .chat-archive__header__user_active {
        border-bottom: var(--active-tab-border-color) var(--tab-border-height) solid;
    }

    .chat-archive__header__user_unread:after{
        content: '*';
        color: var(--unread-color);
        width: 0;
        height: 0;
        font-weight: bold;
        animation: blur .75s ease-out infinite;
    }

    .chat-archive__header__user_birthday:after{
        content: '🎈';
        width: 0;
        height: 0;
    }

    .chat-archive__body {
        flex: 1;
        overflow-y: auto;
        margin: var(--indent-size);
        position: relative;
    }

    .chat-archive__body__messages {
        display: flex;
        flex-direction: column;
    }

    .chat-archive__body__loader {
        position: absolute;
        /*width: 0;*/
        /*height: 0;*/
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    /*.chat-archive__body__loader__loading{*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*}*/

    .chat-archive__body__messages_next{
        margin-top: var(--indent-size);
    }


    .chat-archive__filter {
        display: flex;
        flex-direction: row;
    }

    .chat-archive__footer {
        min-height: var(--block-height-big);
        display: flex;
        overflow-y: visible;
    }

    .chat-archive__footer .z-input{
        margin-bottom: 0;
    }

    .chat-archive__footer__paginate{
        flex: 1;
    }

    .chat-archive__footer__search{
        margin-left: var(--indent-size);
        margin-right: var(--indent-size);
        display: flex;
        flex: 1;
        flex-direction: row;
    }

    .chat-archive__footer__search__btn{
        max-width: 2.5rem;
    }
</style>
