<template lang="pug">
div(class="chat__root")
    div(class="chat__header" @mousedown="editorMethods.preventBlur")
        button(:class="mainRoomBtnClass" @click="openMainRoom" v-html="'ЧАТ'")
        chat-user(v-for="(user, key) in chatUsers"
            :user="user"
            :displayed_name="user.displayed_name"
            :key="'user'+key"
            :class=`chatUserClass(user)`
            @click="openPrivateMessages(`user_${user.id}`, user)"
            )
    div(class="chat__body" @mousedown="preventBlurBody")
        //@mousedown="editorMethods.preventBlur"
        div(class="chat__body__messages" ref="refBodyMessages" @click="messagesClick")
            chat-message(v-for="(chatMessage, index) in messagesWaitCurrentRoom" :key="`chat_messages_wait_${chatMessage.uuid}`"
                :user="userData"
                :chatMessage="chatMessage"
                :is_wait="true"
                :is_editing="chatMessage.uuid === editingUuid"
                :room_id="currentRoomId"
                @openPrivateMessages="openPrivateMessages(`user_${user.id}`,user)"
                @startEditing="startEditing(chatMessage)"
                @stopEditing="stopEditing")
            chat-message(v-for="(chatMessage, index) in messagesCurrentRoom" :key="`chat_messages_${chatMessage.uuid}`"
                :user="chatMessage.user"
                :chatMessage="chatMessage"
                :is_wait="false"
                :is_editing="chatMessage.uuid === editingUuid"
                :room_id="currentRoomId"
                @openPrivateMessages="openPrivateMessages(`user_${chatMessage.user.id}`, chatMessage.user)"
                @startEditing="startEditing(chatMessage)"
                @stopEditing="stopEditing"
                )
            z-btn(class="chat__body__messages_next" :transparent="true" :loading="isLoadingMessagesNext"  @click="loadNextMessages") Загрузить ещё
    div(class="chat__footer" @mousedown="editorMethods.preventBlur")
        chat-commands(v-show="showCommands" ref="refCommands" @command="insertCommand" @ready="commandsReady"  @mousedown="editorMethods.preventBlur")
        z-editor(v-model="chatText"
            placeholder="Сообщения для бога сообщений"
            @keydown.enter="sendMessage($event)"
            @ready="editorReady"
            @change="editorChange"
            @keydown.down="moveSelected(1, $event)"
            @keydown.up="moveSelected(-1, $event)"
            class="chat__footer__editor")
        z-btn(class="z-btn chat__footer__send" icon="send" :transparent="true" @click="sendMessage"  @mousedown="editorMethods.preventBlur")
</template>

<script setup>
import { ref, computed, defineEmits, defineProps, nextTick } from 'vue'
import {v4 as uuidv4} from 'uuid'
import io from '../../plugins/socketio'
import axios from 'axios'
import ChatUser from './Chat-user'
import ChatMessage from './Chat-message'
import ChatCommands from './Chat-commands'
import { scrollToMessage, messagesClick, updateUserInMessages } from './chatFuncs'
import commandsList from './commands'
import ZEditor from '../../components/z-editor'
import { convertToPlain } from '../../plugins/editor'
import { DB } from '../../plugins/goDB'
const db_chat_messages = DB.table('chat_messages');

const emit = defineEmits(['append_to_route'])
const props = defineProps({
    panel: {}
})

import { useUsers } from '../../store/users'
import { useAuth } from '../../store/auth'

const { userData } = useAuth()

const chatUuid = uuidv4()
const mainRoomId = 'chat';
const currentRoomId = ref(mainRoomId)
const selectedUser = ref({})
const showCommands = ref(false)

const messagesMainRoom = ref([])
const messagesWaitMainRoom = ref([])
const messagesPrivateRoom = ref([])
const messagesWaitPrivateRoom = ref([])

const refEditor = ref(null)
const editorMethods = {}

function editorReady({ editor, preventBlur } ){
    refEditor.value = editor
    editorMethods.preventBlur = preventBlur
}

function readProps(){
    if ('room_id' in props.panel && props.panel.room_id !== mainRoomId){
        currentRoomId.value = props.panel.room_id
    } else {
        emit('append_to_route', { room_id: mainRoomId })
    }
}
readProps()


// region users

const { usersState, addUnread, delUnread, setMainRoomUnread, mainRoomOpen, mainRoomClose, mainRooms, mainRoomUnread } = useUsers()

const mainRoomBtnClass = computed(()=>{return {
    'chat__header__user': true,
    'chat__header__user_online': true,
    'chat__header__user_unread': mainRoomUnread.value,
    'chat__header__user_active': currentRoomId.value===mainRoomId
}})

function chatUserClass(user){return{
    'chat__header__user': true,
    'chat__header__user_active': currentRoomId.value ===`user_${user.id}`,
    'chat__header__user_unread': user.unread,
    'chat__header__user_birthday': user.birthday,
    'chat__header__user_online': user.online
}}

const chatUsers = computed(()=>{
    let resultUsers = []
    const mergedUsers = { ...usersState.value.birthdays, ...usersState.value.unread, ...selectedUser.value }

    usersState.value.activeUsers.forEach((user) => {
        let chatUser = { ...user }
        let userKey = `user_${user.id}`

        chatUser['online'] = true
        chatUser['unread'] = false
        chatUser['birthday'] = false

        if (userKey in mergedUsers) {
            chatUser['birthday'] = mergedUsers[userKey]['birthday']
            chatUser['unread'] = mergedUsers[userKey]['unread']
            delete mergedUsers[userKey]
        }
        resultUsers.push(chatUser)
    })

    resultUsers = resultUsers.concat(Object.values(mergedUsers))
    return resultUsers
})

// endregion

function getMessagesCurrentRoom() {
    return (currentRoomId.value === mainRoomId) ? messagesMainRoom : messagesPrivateRoom
}

function getMessagesWaitCurrentRoom() {
    return (currentRoomId.value === mainRoomId) ? messagesWaitMainRoom : messagesWaitPrivateRoom
}

const messagesCurrentRoom = computed(()=>{
    return getMessagesCurrentRoom().value
})

const messagesWaitCurrentRoom = computed(()=>{
    return getMessagesWaitCurrentRoom().value
})

function openMainRoom() {
    currentRoomId.value = mainRoomId
    selectedUser.value = {}
    emit('append_to_route', { room_id: mainRoomId })
    mainRoomOpen(chatUuid)
    setMainRoomUnread(false)
}

function getSelectedUser(userRoomId, user){
    let res = {}
    res[userRoomId] =  { ...user }
    res[userRoomId]['unread'] = false
    return res
}

function openPrivateMessages (userRoomId, user) {
    getMessages(userRoomId, messagesPrivateRoom, ()=>{
        currentRoomId.value = userRoomId
        selectedUser.value = getSelectedUser(userRoomId, user)
        delUnread(userRoomId)
        emit('append_to_route', {room_id: userRoomId})
        if (messagesPrivateRoom.value.length>0){
            scrollToMessage(messagesPrivateRoom.value[0].uuid, refBodyMessages)
        }
    })
    readWaitMessages(userRoomId, messagesWaitPrivateRoom).then(()=>{
        sendWaitMessages(messagesWaitPrivateRoom)
    })
    mainRoomClose( chatUuid )
}

function getMessages(room_id, messages, callback=null){
    if (!room_id) {
        room_id = currentRoomId.value
    }
    let get_messages_params = { 'room_id': room_id }

    io.emit('get_messages', get_messages_params, (data) => {
        messages.value = data
        if (callback) {
            callback()
        }
    })
}

async function loadMessages() {
    getMessages(mainRoomId, messagesMainRoom)
    await readWaitMessages(mainRoomId, messagesWaitMainRoom)
    sendWaitMessages(messagesWaitMainRoom)

    if (currentRoomId.value !== mainRoomId){
        loadPmUser(currentRoomId.value)
        getMessages(currentRoomId.value, messagesPrivateRoom)
        await readWaitMessages(currentRoomId.value, messagesWaitPrivateRoom)
        sendWaitMessages(messagesWaitPrivateRoom)
        delUnread( currentRoomId.value)
    } else {
        mainRoomOpen(chatUuid)
        setMainRoomUnread(false)
    }
}

function loadPmUser(userRoomId){
    axios.get('/get_user_by_room_id', { params: { room_id: userRoomId} })
        .then(response => {
            selectedUser.value = getSelectedUser(userRoomId, response.data)
        })
}

io.on('connect', loadMessages)
io.on('reconnect', loadMessages)
if (io.connected) {
    loadMessages()
}

async function readWaitMessages(room, messagesWait) {
    const roomId = `${room}_${userData.value.id}`
    const waitMessages = await db_chat_messages.findAll((item) => {
        return item.room === roomId
    })
    waitMessages.sort((first, second) => {
        return first.time - second.time
    })
    messagesWait.value = waitMessages
}

function sendWaitMessages(waitMessages) {
    waitMessages.value.forEach(message => {
        emitMessage(message, 'wait')
    })
}

// region next messages

const isLoadingMessagesNext = ref(false)
const refBodyMessages = ref(null)

const loadNextMessages = ()=>{
    let messagesCurrentRoom = getMessagesCurrentRoom()

    if (messagesCurrentRoom.value.length === 0) {
        return
    }

    let get_messages_params = {
        'room_id': currentRoomId.value,
        'last_id': messagesCurrentRoom.value[messagesCurrentRoom.value.length - 1].id
    }

    isLoadingMessagesNext.value = true
    io.emit('get_messages', get_messages_params, (data) => {
        isLoadingMessagesNext.value = false
        messagesCurrentRoom.value = messagesCurrentRoom.value.concat(data)
        nextTick(() => {
            if (data.length > 0) { //Скролл на загруженное сообщение
                scrollToMessage(data[0].uuid, refBodyMessages)
            }
        })
    })
}

// endregion

// region send message

const chatText = ref('')

function editorChange( html ) {
    showCommands.value = (html && html === '<p>/</p>');
}

const commandsMethods = {}
function commandsReady({moveSelected, selectCommand}){
    commandsMethods.moveSelected = moveSelected
    commandsMethods.selectCommand = selectCommand
}

const refCommands = ref( null )
function moveSelected(direction, event ) {
    if (showCommands.value) {
        commandsMethods.moveSelected(direction)
        event.preventDefault()
    }
}

async function sendMessage (event) {
    if (event.shiftKey) {
        return
    }

    let messageText = convertToPlain ( chatText.value )


    if (messageText.trim() === '/') {
        event.preventDefault()
        event.stopPropagation()
        commandsMethods.selectCommand()
        return
    }

    if (fetchCommands(messageText.trim())) {
        return
    }

    let isMe = messageText.substring(0, 4).trim() === '/me'
    if (isMe) {
        messageText = messageText.substring(4, messageText.length).trim()
    }

    if (messageText.trim() !== '') {
        let newMessage = {
            text: messageText,
            uuid: uuidv4(),
            time: Math.floor(Date.now() / 1000),
            user_id: userData.value.id,
            user_displayed_name: userData.value.displayed_name,
            is_me: isMe,
            room: currentRoomId.value + '_' + userData.value.id,
            room_id: currentRoomId.value
        }

        chatText.value = ''
        await db_chat_messages.add(newMessage)
        messagesWaitCurrentRoom.value.splice(0, 0, newMessage)
        emitMessage(newMessage, 'new')
    }
}

function emitMessage (message, action) {
    message['action'] = action
    io.emit('chat_text', message)
}

function insertCommand (command){
    chatText.value = command.insert
    showCommands.value = false;
    refEditor.value.commands.focus();
}

function fetchCommands (messageText) {
    for (let index in commandsList) {
        let command = commandsList[index]
        if (command.regExp) {
            let matches = command.regExp.exec(messageText)
            if (matches) {
                emitCommand(command.value, matches)
                chatText.value = ''
                return true
            }
        }
    }
}

function emitCommand (command, matches) {
    io.emit('chat_command', {
        command: command,
        matches: matches
    }, callback => {
        if (!!callback && 'pm' in callback) {
            openPrivateMessages(`user_${callback.pm.id}`, callback.pm)
        }
    })
}

// endregion


// region on messages

io.on('chat_message', onSocketChatMessage)
io.on('messages', messages=>{
    messages.forEach(message=>{
        onSocketChatMessage(message)
    })
})

io.on('update_user', user_data =>{
    updateUserInMessages(user_data, messagesMainRoom)
    updateUserInMessages(user_data, messagesWaitMainRoom)
    if (currentRoomId.value !== mainRoomId){
        updateUserInMessages(user_data, messagesPrivateRoom)
        updateUserInMessages(user_data, messagesWaitPrivateRoom)
    }
})

function onSocketChatMessage (message) {
    let isPrivate = Boolean(message['pm_user_id'])
    if (!isPrivate) {
        message.room_id = mainRoomId
        onChatMessage(message, messagesMainRoom, messagesWaitMainRoom)
    } else {
        message.room_id = `user_${(message.user_id === userData.value.id) ? message.pm_user_id : message.user.id}`
        onChatMessage(message, messagesPrivateRoom, messagesWaitPrivateRoom)
    }
}

function onChatMessage(message, chatMessages, chatMessagesWait) {
    if (message.action === 'new') {
        appendMessage(message, chatMessages, chatMessagesWait)
    } else if (message.action === 'wait' && message.was_new) {
        appendMessage(message, chatMessages, chatMessagesWait)
    } else if (message.action === 'wait' && !message.was_new) {
        editMessage(message, chatMessages)
        deleteFromWait(chatMessagesWait, message)
    } else if (message.action === 'edit' || message.action === 'delete') {
        editMessage(message, chatMessages)
    }
}

function appendMessage(message, chatMessages, chatMessagesWait) {
    if (message.room_id === mainRoomId) {
        chatMessages.value.splice(0, 0, message)
        if (message.room_id !== currentRoomId.value) {
            setMainRoomUnread(true)
        }
    } else if (message.room_id === currentRoomId.value) {
        chatMessages.value.splice(0, 0, message)
        if (message.room_id !== mainRoomId) {
            delUnread(message.room_id)
        }
    } else if (message.room_id && message.user.id !== userData.value.id) {
        addUnread(message.user)
    }

    deleteFromWait(chatMessagesWait, message)
}

function deleteFromWait(chatMessagesWait, message) {
    let waitMessageIndex = chatMessagesWait.value.findIndex(findMessage => findMessage.uuid === message.uuid)
    if (waitMessageIndex !== -1) {
        chatMessagesWait.value.splice(waitMessageIndex, 1)
        db_chat_messages.delete({uuid: message.uuid})
    }
}

function editMessage(message, chatMessages) {
    let action = message.action
    let chatMessageIndex = chatMessages.value.findIndex(findMessage => findMessage.uuid === message.uuid)
    if (chatMessageIndex !== -1 && action === 'edit') {
        Object.assign(chatMessages.value[chatMessageIndex], message)
    } else if (chatMessageIndex !== -1 && action === 'delete') {
        chatMessages.value.splice(chatMessageIndex, 1)
    }
}

// endregion

// region editing

const editingUuid = ref('')

function startEditing (chatMessage) {
    if (chatMessage.uuid !== editingUuid.value) {
        editingUuid.value = chatMessage.uuid
    }
}

function stopEditing () {
    editingUuid.value = ''
}

// endregion

function preventBlurBody(event){
    // // debugger
    if (event.target.className==='forum-link'){
    //     editorMethods.preventBlur(event, true)
        event.preventDefault();
    }
    // // @mousedown="editorMethods.preventBlur"
    // editorMethods.preventBlur()
}

</script>

<style scoped>
    .chat__root {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .chat__header {
        height: var(--block-height-big);
        min-height: var(--block-height-big);
        background: var(--chat-header-background);
        width: 100%;
        flex: 0;
        display: inline-flex;
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: var(--indent-size);
    }

    .chat__header__user {
        --chat__header__user_height: 100%;
        white-space: nowrap;
        margin: 0 calc(var(--indent-size)/5);
        height: var(--chat__header__user_height);
        line-height: var(--block-height-big);
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom: var(--inactive-tab-border-color) var(--tab-border-height) solid;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }

    .chat__header__user_online{
        filter: none;
    }

    .chat__header__user_active {
        border-bottom: var(--active-tab-border-color) var(--tab-border-height) solid;
        /*filter: none;*/
    }

    .chat__header__user_unread:after{
        content: '*';
        color: var(--unread-color);
        width: 0;
        height: 0;
        font-weight: bold;
        animation: blur .75s ease-out infinite;
    }

    .chat__header__user_birthday:after{
        content: '🎈';
        width: 0;
        height: 0;
    }

    @keyframes blur{
      from{
          text-shadow:0px 0px 10px var(--unread-color),
          0px 0px 10px var(--unread-color),
          0px 0px 25px var(--unread-color),
          0px 0px 25px var(--unread-color),
          0px 0px 25px var(--unread-color),
          0px 0px 25px var(--unread-color),
          0px 0px 25px var(--unread-color),
          0px 0px 25px var(--unread-color),
          0px 0px 50px var(--unread-color),
          0px 0px 50px var(--unread-color),
          0px 0px 50px var(--unread-color),
          0px 0px 150px var(--unread-color)
      }
    }

    .chat__body {
        flex: 1;
        overflow-y: auto;
        margin: var(--indent-size);
    }

    .chat__body__messages {
        display: flex;
        flex-direction: column;
        margin-right: var(--indent-size);
    }

    .chat__body__messages_next{
        margin-top: var(--indent-size);
    }


    .chat__footer {
        /*display: none;*/
        min-height: var(--block-height-big);
        max-height: 40%;
        background: var(--editor-background);
        display: flex;
        /*overflow-y: visible;*/
        border-top: var(--chat_footer-border-top);
        padding-left: var(--indent-size-small);
    }

    .chat__footer__editor {
        flex: 1;
        width: 100%;
        outline: none;
        /*height: 100%;*/
        overflow-x: hidden;
        /* margin-top: 1px; */
        /* margin-left: 2px; */
        overflow-y: auto;
        margin-top: var(--indent-size);
        margin-left: var(--indent-size);
        margin-right: var(--indent-size);
        margin-bottom: var(--indent-size);
        max-height: 10rem;
    }

    .chat__footer__editor ::v-deep(.ProseMirror) {
        overflow-y: auto;
        /*height: 100%;*/
        /*line-height: var(--block-height-big);*/
        /*padding: var(--indent-size);*/
        /*padding-top: var(--indent-size);*/
    }

    .chat__footer__editor p {
        margin: 0;
        padding: 0;
        margin-top: 1px;
    }

    .chat__footer__editor > div {
        flex: 1;
        background: var(--editor-background);
        height: 100%;
        width: 100%;
        outline: none;
        padding: var(--indent-size);
        /*overflow-y: visible;*/
        /*overflow-x: visible;*/
    }


    .chat__footer__send {
        height: 100%;
        width: 2.5rem;
    }
</style>
